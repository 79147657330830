<template>
  <Defaultlayout :userprofile="userprofile">
    <PageSlider></PageSlider>
    <section class="about-us">
      <div class="container">
        <h1 class="page-heading">About Us</h1>
        <div class="description">
          <p>
            Welcome to Ghar Bazaar, your ultimate destination for convenient and hassle-free desi grocery shopping. At
            Ghar Bazaar, we understand the importance of convenience in today's fast-paced world. That's why we bring
            the grocery store to you, offering a seamless online shopping experience that allows you to browse, select,
            and purchase your favorite desi groceries from the comfort of your home.
          </p>
          <p>With our local delivery service, getting your groceries has never been easier. Our fast and efficient
            delivery team ensures that your order is promptly delivered right to your doorstep, saving you time and
            effort. Whether you need fresh produce, pantry staples, or specialty items, Ghar Bazaar has you covered.</p>
          <p>Experience the convenience of Ghar Bazaar today and let us take the stress out of grocery shopping.</p>

          <p>
            <strong>
              WHY ORDER THROUGH GHAR BAZAAR?
            </strong>
          </p>

          <ul>
            <li>
              <p>
                Free home delivery options available
              </p>
            </li>
            <li>
              <p>
                Same in-store prices.
              </p>
            </li>
            <li>
              <p>
                No membership
              </p>
            </li>
            <li>
              <p>
                Everything in the store will be delivered. Dairy, frozen, vegetables & fruits are delivered in temperature-controlled vans.
              </p>
            </li>
            <li>
              <p>
                We deliver India Bazaar & Indiaco groceries.
              </p>
            </li>
            <li>
              <p>
                We cover majority neighboring cities near to delivery centers in DFW and Atlanta metroplex.
              </p>
            </li>
          </ul>
        </div>

        <!-- <ul class="description">
          <li>
            <h3>
              <span class="font-bold"> Ghar Bazaar Home Delivery Service </span>
            </h3>
            <p>With the success of 9 India Bazaar stores in DFW metroplex, Texas and an out of state INDIACO location in Naperville, IL, India Bazaar family is now ready to take the grocery shopping to the next level with Ghar Bazaar. Ghar Bazaar website and app not only gives the user the convenience and ease of shopping South Asian groceries online but also provides them fast and contactless.</p>

                  <p>Find our apps on Apple Store & Google Play Store</p>
          </li>
          <li>
            <h3><span class="font-bold">We Serve </span></h3>
            <p>Dallas, Denton, Keller, Fort Worth, Grand Prairie, Grapevine, Euless, Bedford, Arlington, Flower Mound, Coppell, Farmers Branch, Irving, Little Elm, Lewisville, Prosper, Aubrey, Corinth, Krogerville, Crossroads, Oak Point, The Colony, Frisco, Allen, Plano, Carrollton, McKinney, Richardson, Rockwall, Wylie, Murphy, Garland, Rowlett, Parker, Sachse, Highland Park, Anna, Celina, Roanoke, University Park, & Addison and many other cities.</p>
          </li>
          
          <li>
            <h3><span class="font-bold">Following terms for order fulfillment through Ghar Bazaar app:</span></h3>
            <ul>
              <li><span class="font-bold">Free Delivery up to 25 miles:</span> all orders worth $39 or more and under 25 miles from 1640 FM 423, Little Elm, Tx-75036, will get FREE home delivery. Orders less than $39 and those under 25 miles will be charged a delivery fee of $4.
              </li>
              <li><span class="font-bold">Delivery fee for orders 25 miles to 50 miles:</span> all orders worth $39 or more and under 25 miles from 1640 FM 423, Little Elm, Tx-75036, will get FREE home delivery. Orders less than $39 and those under 25 miles will be charged a delivery fee of $4.
              </li>
              <li>Order acceptance is based on the delivery radius. If your address falls outside our radius, you will be notified.</li>
              <li>
                The weight of the fresh vegetable and fruits will be closer to what you order, we cannot guarantee the exact match of the weight ordered. If the fresh produce you received has quality issues, please inform us within 2-3 hours from the time our driver dropped the delivery.
              </li>
              <li>
                The delivery driver shall perform CONTACTLESS DELIVERY and will leave the order at your door.
              </li>
              <li>
                We do not take any responsibility after our products have been delivered to the doorstep.
              </li>
              <li>
                All orders will be processed within 3-5 days. Our team will call you once your order reaches the processing stage.
              </li>
              <li>
                All orders will be delivered within 24 hours from the time the payment is received.
              </li>
              <li>
                Return Policy: In observance of COVID-19, we currently have a no return & no exchange policy. No returns will be accepted on fresh fruits, vegetables, frozen items, dairy, and other perishable products. Exceptions on other items such as grocery, non-food, utensils, etc, might be made on a case-by-case basis contingent they are reported within 1-2 days from the day it is dropped at your doorstep. Any damages/quality issues reported after that period will not qualify for a refund or exchange.
              </li>
              <li>
                Please note that some items you order might be unavailable or have quantity restrictions, in that case, the order will be adjusted to what we have available.
              </li>
              <li>
                Our special home delivery helpline <a href="tel:(214) 872-1038">(214) 872-1038</a> is now up to answer all your order-related queries! You can also reach out to us with any issues you have at support@indiabazaar.us.
              </li>
            </ul>
          </li>
        </ul> -->
      </div>
    </section>
  </Defaultlayout>
</template>
<script>
import Defaultlayout from "../../layouts/Defaultlayout";
import PageSlider from "../pages/PageSlider";
export default {
  name: "AboutUs",
  components: {
    Defaultlayout,
    PageSlider,
  },
  data() {
    return {
      loading: true,
      userprofile: null,
    };
  },
  mounted() {
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
  },
};
</script>